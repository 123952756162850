<template>
    <b-card title="Customer Preference">
        <validation-observer ref="simpleRules">
            <b-form>
                <b-row>
                    <b-col md="4">
                        <b-form-group label="Customer Preference">
                            <b-form-input v-model="customerPreference" placeholder="Please Enter Customer Preference" />
                        </b-form-group>
                    </b-col>
                    <b-col md="2">
                        <b-button variant="primary" class="mr-2 mt-2" @click="addUpdateCustomerPereference">
                            <feather-icon icon="PlusIcon" class="mr-50" />{{ addUpdateButton }}
                        </b-button>
                    </b-col>
                </b-row>
                <hr>
                <div>
                    <!-- search input -->
                    <div class="custom-search d-flex justify-content-end">
                        <b-form-group>
                            <div class="d-flex align-items-center">
                                <label class="mr-1">Search</label>
                                <b-form-input v-model="searchTerm" placeholder="Search" type="text"
                                    class="d-inline-block" />
                            </div>
                        </b-form-group>
                    </div>

                    <!-- table -->
                    <vue-good-table :line-numbers="true" :columns="columns" :rows="rows" :rtl="direction"
                        :search-options="{
                            enabled: true,
                            externalQuery: searchTerm
                        }" :pagination-options="{
    enabled: true,
    perPage: pageLength
}">
                        <template slot="table-row" slot-scope="props">


                            <!-- Column: Action -->
                            <span v-if="props.column.field === 'action'">
                                <span>
                                    <b-button @click="editButton(props.row.id, props.row.name)"
                                        variant="flat-primary"><feather-icon icon="EditIcon"
                                            class="text-body align-middle mr-25" /></b-button>
                                    <b-button @click="deleteCustomerPreferences(props.row.id)"
                                        variant="flat-primary"><feather-icon icon="TrashIcon"
                                            class="text-body align-middle mr-25" /></b-button>
                                </span>
                            </span>

                            <!-- Column: Common -->
                            <span v-else>
                                {{ props.formattedRow[props.column.field] }}
                            </span>
                        </template>

                        <!-- pagination -->
                        <template slot="pagination-bottom" slot-scope="props">
                            <div class="d-flex justify-content-between flex-wrap">
                                <div class="d-flex align-items-center mb-0 mt-1">
                                    <span class="text-nowrap ">
                                        Showing 1 to
                                    </span>
                                    <b-form-select v-model="pageLength" :options="['10', '20', '50','100']" class="mx-1"
                                        @input="(value) => props.perPageChanged({ currentPerPage: value })" />
                                    <span class="text-nowrap"> of {{ props.total }} entries </span>
                                </div>
                                <div>
                                    <b-pagination :value="1" :total-rows="props.total" :per-page="pageLength"
                                        first-number last-number align="right" prev-class="prev-item"
                                        next-class="next-item" class="mt-1 mb-0"
                                        @input="(value) => props.pageChanged({ currentPage: value })">
                                        <template #prev-text>
                                            <feather-icon icon="ChevronLeftIcon" size="18" />
                                        </template>
                                        <template #next-text>
                                            <feather-icon icon="ChevronRightIcon" size="18" />
                                        </template>
                                    </b-pagination>
                                </div>
                            </div>
                        </template>
                    </vue-good-table>
                </div>
            </b-form>
        </validation-observer>
    </b-card>
</template>
  
<script>
import {
    BAvatar, BCard, BRow, BCol, BForm, BButton, BBadge, BPagination, BFormGroup, BFormInput, BFormSelect, BDropdown, BDropdownItem,
} from 'bootstrap-vue'
import { VueGoodTable } from 'vue-good-table'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import api from '@/store/api'

export default {
    components: {
        VueGoodTable,
        BAvatar,
        BBadge,
        BPagination,
        BFormGroup,
        BFormInput,
        BFormSelect,
        BDropdown,
        BDropdownItem, BCard, BRow, BCol, BForm, BButton
    },
    data() {
        return {
            pageLength: 10,
            dir: false,
            columns: [
                {
                    label: 'Customer Preference',
                    field: 'name',
                },
                {
                    label: 'Action',
                    field: 'action',
                },
            ],
            rows: [],
            searchTerm: '',
            customerPreference: '',
            addUpdateButton: 'Add',
            id: 0,

        }
    },
    methods: {
        getCustomerPreferences() {
            this.id = 0;
            this.customerPreference = '';
            this.addUpdateButton = "Add";
            let self = this;

            var axios = require('axios');
            var data = '';
            axios(api.getApi('get', '/masters/customerPreferences', data))
                .then(function (response) {
                    //console.log(JSON.stringify(response.data));

                    self.rows = response.data;
                })
                .catch(function (error) {
                    console.log(error);
                });
        },
        addUpdateCustomerPereference() {
            let self = this;
            if (!(self.customerPreference == '')) {
                // eslint-disable-next-line
                if (self.addUpdateButton == 'Add') {
                    var axios = require('axios');
                    var data = JSON.stringify({ "name": this.customerPreference });
                    axios(api.getApi('Post', '/masters/customerPreferences', data))
                        .then(function (response) {
                            //console.log(JSON.stringify(response.data));
                            self.$toast({
                                component: ToastificationContent,
                                position: 'top-right',
                                props: {
                                    title: `Customer Preferences Added Successfully!`,
                                    icon: 'SaveIcon',
                                    variant: 'success',
                                },
                            })
                            self.getCustomerPreferences();
                        })
                        .catch(function (error) {
                            console.log(error);
                        });
                } else {
                    let self = this;
                    var axios = require('axios');
                    var data = JSON.stringify({ "name": this.customerPreference });
                    axios(api.getApi('put', '/masters/customerPreferences/' + this.id, data))
                        .then(function (response) {
                            //console.log(JSON.stringify(response.data));
                            self.getCustomerPreferences();
                            self.$toast({
                                component: ToastificationContent,
                                position: 'top-right',
                                props: {
                                    title: `Customer Preferences Updated Successfully!`,
                                    icon: 'SaveIcon',
                                    variant: 'success',
                                },
                            })

                        })
                        .catch(function (error) {
                            console.log(error);
                        });
                }
            } else {
                this.$swal({
                    title: '',
                    text: "Please Enter Customer Preferences.",
                    icon: 'error',
                    customClass: {
                        confirmButton: 'btn btn-primary',
                    },
                    buttonsStyling: false,
                })
            }

        },
        deleteCustomerPreferences(id) {
            let self = this;
            this.$swal({
                title: '',
                text: "Are You Sure You Want To Delete!",
                icon: 'error',
                customClass: {
                    confirmButton: 'btn btn-primary',
                },
                buttonsStyling: false,
            }).then(result => {
                if (result.value) {
                    var axios = require('axios');
                    var data = '';
                    axios(api.getApi('delete', '/masters/customerPreferences/' + id, data))
                        .then(function (response) {
                            //console.log(JSON.stringify(response.data));
                            self.getCustomerPreferences();
                        })
                        .catch(function (error) {
                            console.log(error);
                        });
                }
            })
        },
        editButton(id, name) {
            this.id = id;
            this.addUpdateButton = 'Update';
            this.customerPreference = name;

        }
    },
    computed: {

    },
    created() {
        let self = this;
        self.getCustomerPreferences();
    },
}
</script>
<style lang="css">
@import '~vue-good-table/dist/vue-good-table.css';
</style>
